import { GOOGLE_PAY_TEST_PRIME, SUPPORTED_PAYMENT_NETWORKS } from "@/constants";
import useTappay from "@/libs/hooks/tappay";
import {
  Center,
  Circle,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  useToast,
} from "@chakra-ui/react";
import { useEffect } from "react";
import MobileNavigation from "../MobileNavigation";
import FAIcon from "../FAIcon";
import useAPI from "@/libs/hooks/api";
import { createSearchParams, useNavigate } from "react-router-dom";
import gtmInitiateCheckout from "@/libs/gtm-initiate-checkout";

const getPrime = (prime) =>
  process.env.NODE_ENV !== "production" ? GOOGLE_PAY_TEST_PRIME : prime;

const GooglePayView = ({ active, details, onCancel, onAvailable }) => {
  const [tp, ready] = useTappay();
  const toast = useToast();
  const api = useAPI();
  const navigate = useNavigate();

  useEffect(() => {
    if (!ready) return;
    // check google pay support
    const googlePaySettings = {
      googleMerchantId: "BCR2DN4T2OIK7NCD",
      allowedCardAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
      merchantName: "NightCo",
      billingAddressFormat: "MIN", // FULL, MIN

      allowPrepaidCards: true,
      allowedCountryCodes: ["TW"],
    };
    // making a $1 request to check if google pay is available
    const request = {
      allowedNetworks: SUPPORTED_PAYMENT_NETWORKS,
      price: "1",
      currency: "TWD",
    };
    tp.googlePay.setupGooglePay(googlePaySettings);
    tp.googlePay.setupPaymentRequest(request, function (err, result) {
      if (result.canUseGooglePay) {
        onAvailable();
      }
    });
  }, [onAvailable, ready, tp]);

  useEffect(() => {
    if (!ready) return;
    if (active) {
      const { cost, discount = 0 } = details;
      const value = (cost - discount).toString();

      // wait for google pay button to be shown
      setTimeout(() => {
        tp.googlePay.setupTransactionPrice({
          price: value,
          currency: "TWD",
        });
        tp.googlePay.setupGooglePayButton({
          el: "#google-pay",
          type: "long",
          getPrimeCallback: async (err, rawPrime) => {
            if (err) {
              return toast({
                title: `GooglePay 支付失敗，錯誤原因：${err.msg}`,
                status: "error",
              });
            }
            const prime = getPrime(rawPrime);
            const result = await api.depositGooglePay({
              prime,
              price: details.cost,
            });
            if (!result?.data) {
              toast({
                title: "GooglePay 支付失敗，請重新操作",
                status: "error",
              });
              return onCancel();
            }
            const { tradeId, id } = result.data;
            gtmInitiateCheckout(details.cost, "Google Pay");

            navigate({
              pathname: "/callback/tappay",
              search: createSearchParams({
                rec_trade_id: tradeId,
                order_number: id,
              }).toString(),
            });
          },
        });
      }, 100);
    }
  }, [active, details, ready, tp, toast, onCancel, api, navigate]);

  return (
    <Drawer isOpen={active} onClose={onCancel} placement="bottom">
      <DrawerOverlay />
      <DrawerContent
        containerProps={{ zIndex: MobileNavigation.Z_INDEX + 100 }}
        borderTopRadius={12}
        bg="white"
      >
        <DrawerBody>
          <Flex justify="flex-end" py={2}>
            <Circle role="button" bg="#eeeeee" size={8} onClick={onCancel}>
              <FAIcon type="xmark" color="#939393" fontSize="lg" />
            </Circle>
          </Flex>
          <Center mb={6}>
            <div id="google-pay" />
          </Center>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default GooglePayView;
